import { AppInitializedResponse, GrafanaSyncStatus } from 'types'
import { inArray } from './array'

const hasSyncEnded = inArray([
  GrafanaSyncStatus.SYNC_STATUS_FAILED,
  GrafanaSyncStatus.SYNC_STATUS_FINISHED,
])

export function isInitializationAvailable(
  provisioning?: AppInitializedResponse
) {
  if (!provisioning) {
    return false
  }

  return (
    provisioning.initialized === false &&
    provisioning.grafana_sync_status === null
  )
}

export function isInitializationInProgress(
  provisioning?: AppInitializedResponse
) {
  if (!provisioning) {
    return false
  }

  return provisioning.initialized === false
}

export function isInitializationError(provisioning?: AppInitializedResponse) {
  if (!provisioning || provisioning.grafana_sync_status === null) {
    return false
  }

  return (
    provisioning.initialized === false &&
    hasSyncEnded(provisioning.grafana_sync_status)
  )
}
