/**
 * ##################################################################################################################
 * #                                          !!! -->   NOTE  <-- !!!                                               #
 * #                                                                                                                #
 * #            If you add or update a tracking event, please update the tracking event dashboard:                  #
 * #  https://bi.grafana-ops.net/d/ae1xszgt8zhmoa/grafana-cloud-k6-events?orgId=1&from=now-30d&to=now&timezone=utc  #
 * #                                                                                                                #
 * #                                                                                                                #
 * ##################################################################################################################
 */

import { ProjectId, Test } from 'types'
import { OnboardingTrackAction, OnboardingType } from 'components/Onboarding'
import { InsightRecommendation } from 'types/cloudInsights'

export enum TrackingEventName {
  CTAUpgrade = 'grafana_cloud_k6_cta_upgrade',
  TestCreated = 'grafana_cloud_k6_test_created',
  TestRun = 'grafana_cloud_k6_test_run',
  ErrorBoundary = 'grafana_cloud_k6_error_boundary',
  Onboarding = 'grafana_cloud_k6_onboarding',
  AnalysisTabExplore = 'grafana_cloud_k6_analysis_explore',
  Profiling = 'grafana_cloud_k6_profiling',
  ProjectsList = 'grafana_cloud_k6_projects_list',
  HomePage = 'grafana_cloud_k6_home_page',
  SettingsPage = 'grafana_cloud_k6_settings_page',
  ManagePage = 'grafana_cloud_k6_manage_page',
  LearnPage = 'grafana_cloud_k6_learn_page',
  RecommendationTab = 'grafana_cloud_k6_recommendation_tab',
  TestRunPage = 'grafana_cloud_k6_test_run_page',
}

export enum CTAUpgradeReason {
  MaxConcurrentTests = 'max_concurrent_tests_reached',
  MaxSavedTests = 'max_saved_tests_reached',
  MaxVUsExceeded = 'max_vus_exceeded',
  MaxDurationExceeded = 'max_duration_exceeded',
  TotalLoadZonesExceeded = 'total_load_zones_exceeded',
}

export enum ErrorBoundaryType {
  Local = 'local',
  FullPage = 'full_page',
}

export enum ErrorBoundaryLocalView {
  SchedulesTab = 'schedules_tab',
  WebSocketsTab = 'websockets_tab',
  TracesTab = 'traces_tab',
  ThresholdsTab = 'thresholds_tab',
  ScriptTab = 'script_tab',
  DiffScriptsTab = 'diff_scripts_tab',
  LogsTab = 'logs_tab',
  HttpTab = 'http_tab',
  GrpcTab = 'grpc_tab',
  ChecksTab = 'checks_tab',
  BrowserTab = 'browser_tab',
  AnalysisTab = 'analysis_tab',
  ProfileTab = 'profile_tab',
  RecommendationsTab = 'recommendations_tab',
  BreakdownRow = 'breakdown_row',
  ProjectSettingsLimits = 'project_settings_limits',
  ProjectVUhConsumption = 'project_vuh_consumption',
  StackAPITokens = 'stack_api_tokens',
  PerformanceOverview = 'performance_overview',
  PerformanceInsights = 'performance_insights',
  PerformanceDetails = 'performance_details',
  PerformanceOverviewComparison = 'performance_overview_comparison',
  PerformanceDetailsComparison = 'performance_details_comparison',
  PerformanceBrowserTimeline = 'performance_browser_timeline',
  ProjectRolesTable = 'project_roles_table',
  HomePageProjects = 'home_page_projects',
  HomePageLatestChanges = 'home_page_latest_changes',
}

export enum ErrorBoundaryFullPageView {
  InitializePage = 'initialize_page',
  AppContextProvider = 'app_context_provider',
  ProjectContextProvider = 'project_context_provider',
  RootPage = 'root_page',
  ProjectPage = 'project_page',
  NotificationsTab = 'notifications_tab',
  SettingsPage = 'settings_page',
  StaticIPs = 'static_ips',
  TrendingMetricsModal = 'trending_metrics_modal',
  PageErrorBoundary = 'page_error_boundary',
  RecorderPage = 'recorder_page',
}

export enum AnalysisTabExploreClickTarget {
  exploreButton = 'explore_button',
  viewInExploreButton = 'view_in_explore_button',
  createDashboardButton = 'create_dashboard_button',
  createDashboardSubmitButton = 'create_dashboard_submit_button',
}

export enum ProfilingActionType {
  ClickedGoToProfilesButton = 'go_to_profiles_button',
  ClickedTabWithData = 'view_profile_tab_with_data',
}

export enum ProjectCardClickTarget {
  Project = 'project',
  Rename = 'rename',
  ManagePermissions = 'manage_permissions',
  ManageLimitsAndQuotas = 'manage_limits_and_quotas',
  DeleteProject = 'delete_project',
}

export enum MembersClickTarget {
  ViewAll = 'view_all',
  ManageUsers = 'manage_users',
}

export enum MostRecentRunClickTarget {
  MostRecentRun = 'most_recent_run',
}

export enum StarredProjectsClickTarget {
  EditStarredProjects = 'edit_starred_projects',
  SaveStarredProjects = 'save_starred_projects',
  SeeAllProjects = 'see_all_projects',
}

export enum UsefulLinksClickTarget {
  Documentation = 'documentation',
  ContactSupport = 'contact_support',
  SendFeedback = 'send_feedback',
}

export enum LatestChangesClickTarget {
  Link = 'link',
}

export enum SettingsNavClickTarget {
  PersonalAPIToken = 'personal_api_token',
  GrafanaStackAPIToken = 'grafana_stack_api_token',
  EnvironmentVariables = 'environment_variables',
  LimitsAndQuotas = 'limits_and_quotas',
  Notifications = 'notifications',
}

export enum ManageNavClickTarget {
  ScheduledTests = 'scheduled_tests',
  AuditTrail = 'audit_trail',
  UsageReports = 'usage_reports',
  LoadZones = 'load_zones',
  SavedTests = 'saved_tests',
  StaticIPs = 'static_ips',
}

export enum LearnNavClickTarget {
  CLIInstructions = 'cli_instructions',
  OSSDocs = 'oss_docs',
  JavaScriptAPIDocs = 'javascript_api_docs',
  GCk6Docs = 'gck6_docs',
  IntegrationsDocs = 'integrations_docs',
}

export enum RecommendationsTabClickTarget {
  TracesDocs = 'traces_docs',
  PlzDocs = 'plz_docs',
  ManagePlzDocs = 'manage_plz',
  SyntheticMonitoringDocs = 'synthetic_monitoring_docs',
  ExploreSynthetics = 'explore_synthetics',
  SchedulingTestsDocs = 'scheduling_tests_docs',
  BaselineTestsDocs = 'baseline_tests_docs',
  ChecksDocs = 'checks_docs',
  ThresholdsDocs = 'thresholds_docs',
}

export enum TestRunPageTabClickTarget {
  Thresholds = 'thresholds',
  Checks = 'checks',
  HTTP = 'http',
  WS = 'ws',
  GRPC = 'grpc',
  Script = 'script',
  Logs = 'logs',
  Traces = 'traces',
  Browser = 'browser',
  Analysis = 'analysis',
  Profile = 'profile',
  Recommendations = 'recommendations',
}

export enum TrackingActionType {
  Click = 'click',
}

interface TrackingEventBase {
  eventName: TrackingEventName
  projectId?: ProjectId
}

export interface CTAUpgradeTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.CTAUpgrade
  reason: CTAUpgradeReason
  action: TrackingActionType
}

interface TestCreatedTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.TestCreated
  source: Test['creation_process']
}

interface TestRunTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.TestRun
  source: Test['creation_process']
}

interface ErrorBoundaryFullPageTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.ErrorBoundary
  type: ErrorBoundaryType.FullPage
  isApiError: boolean
  view: ErrorBoundaryFullPageView
}

interface ErrorBoundaryLocalTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.ErrorBoundary
  type: ErrorBoundaryType.Local
  isApiError: boolean
  view: ErrorBoundaryLocalView
}

interface OnboardingEvent extends TrackingEventBase {
  eventName: TrackingEventName.Onboarding
  type: `${OnboardingType}:${OnboardingTrackAction}`
}

interface ProfilingEvent extends TrackingEventBase {
  eventName: TrackingEventName.Profiling
  type: ProfilingActionType
}

interface AnalysisTabExploreTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.AnalysisTabExplore
  action: TrackingActionType.Click
  target: AnalysisTabExploreClickTarget
}

interface ProjectsListTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.ProjectsList
  action: TrackingActionType.Click
  target: ProjectCardClickTarget | MembersClickTarget | MostRecentRunClickTarget
}

interface HomePageTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.HomePage
  action: TrackingActionType.Click
  target:
    | ProjectCardClickTarget
    | MembersClickTarget
    | StarredProjectsClickTarget
    | UsefulLinksClickTarget
    | LatestChangesClickTarget
}

interface SettingsPageTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.SettingsPage
  action: TrackingActionType.Click
  target: SettingsNavClickTarget
}

interface ManagePageTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.ManagePage
  action: TrackingActionType.Click
  target: ManageNavClickTarget
}

interface LearnPageTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.LearnPage
  action: TrackingActionType.Click
  target: LearnNavClickTarget
}

interface RecommendationsTabTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.RecommendationTab
  action: TrackingActionType.Click
  target: RecommendationsTabClickTarget
}

interface RecommendationsTabViewTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.RecommendationTab
  view: InsightRecommendation
}

interface TestRunPageTrackingEvent extends TrackingEventBase {
  eventName: TrackingEventName.TestRunPage
  action: TrackingActionType.Click
  target: TestRunPageTabClickTarget
}

export type TrackingEvent =
  | CTAUpgradeTrackingEvent
  | TestCreatedTrackingEvent
  | TestRunTrackingEvent
  | ErrorBoundaryFullPageTrackingEvent
  | ErrorBoundaryLocalTrackingEvent
  | OnboardingEvent
  | ProfilingEvent
  | AnalysisTabExploreTrackingEvent
  | ProjectsListTrackingEvent
  | HomePageTrackingEvent
  | SettingsPageTrackingEvent
  | ManagePageTrackingEvent
  | LearnPageTrackingEvent
  | RecommendationsTabViewTrackingEvent
  | RecommendationsTabTrackingEvent
  | TestRunPageTrackingEvent
