import { useOrganization } from 'data/useOrganization'
import React, { AnchorHTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'

import { getGrafanaUrl } from 'utils/env'
import { spacing } from 'utils/styled'

interface SupportLinkProps {
  children: ReactNode
  onClick?: AnchorHTMLAttributes<HTMLAnchorElement>['onClick']
}

const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: ${spacing(0.5)};
`

export const SupportLink = ({ children, ...rest }: SupportLinkProps) => {
  const organization = useOrganization()

  const path = organization?.grafana_org_slug
    ? `/orgs/${organization.grafana_org_slug}/tickets/new?supportTopic=grafana%20k6`
    : `/contact`

  const link = getGrafanaUrl(path)

  return (
    <StyledLink href={link} target="_blank" rel="noreferrer" {...rest}>
      {children}
    </StyledLink>
  )
}
