import React from 'react'
import styled from 'styled-components'

import { PluginPagePlaceholder } from 'components/PluginPagePlaceholder'
import { ErrorState } from 'components/ErrorState'
import { ErrorBoundaryFullPageView } from 'services/tracking.types'
import { Flex } from 'components/Flex'
import { Organization } from 'types'
import { getGrafanaUrl } from 'utils/env'
import { EmptyState } from 'components/EmptyState'

export function AppContextErrorState({ error }: { error: unknown }) {
  return (
    <PluginPagePlaceholder>
      <ErrorState
        error={error}
        view={ErrorBoundaryFullPageView.AppContextProvider}
      />
    </PluginPagePlaceholder>
  )
}

export function AppContextFeatureToggleErrorState({
  org,
}: {
  org?: Organization
}) {
  const path = org?.grafana_org_slug
    ? `/orgs/${org.grafana_org_slug}/tickets/new?supportTopic=grafana%20k6`
    : `/help/`

  const link = getGrafanaUrl(path)

  return (
    <PluginPagePlaceholder>
      <EmptyState variant="call-to-action" message="Contact support" fullPage>
        <Content>
          <Flex direction="column" gap={1}>
            <span>
              Grafana Cloud k6 requires the following feature toggles to be
              enabled:
            </span>

            <ul>
              <li>
                <code>accessControlOnCall</code>
              </li>
              <li>
                <code>nestedFolders</code>
              </li>
              <li>
                <code>externalServiceAccounts</code>
              </li>
            </ul>
            <p>
              Please{' '}
              <a target="_blank" href={link} rel="noreferrer">
                contact support
              </a>{' '}
              to enable these features.
            </p>
          </Flex>
        </Content>
      </EmptyState>
    </PluginPagePlaceholder>
  )
}

const Content = styled.div`
  text-align: left;
`
