import {
  colorManipulator,
  ThemeBreakpointsKey,
  ThemeColors,
  ThemeTypography,
  ThemeTypographyVariant,
} from '@grafana/data'
import { DefaultTheme } from 'styled-components'

const { alpha } = colorManipulator

export interface PropsWithTheme {
  theme: DefaultTheme
}

type Spacing = string | number

// styled-components need to migrate types for v6 https://github.com/styled-components/styled-components/issues/4062
// watching this PR: https://github.com/styled-components/styled-components/pull/4126
// type StyledFunction = InterpolationFunction<PropsWithTheme>
type StyledFunction = any

/**
 * Shorthand for getting a specific spacing.
 *
 * ```
 * `${spacing(2)}`
 * ```
 * is the same as
 * ```
 * `${({ theme }) => theme.spacing(2)}`
 * ```
 */
export function spacing(): StyledFunction
export function spacing(value: Spacing): StyledFunction
export function spacing(topBottom: Spacing, rightLeft: Spacing): StyledFunction
export function spacing(
  top: Spacing,
  rightLeft: Spacing,
  bottom: Spacing
): StyledFunction
export function spacing(
  top: Spacing,
  right: Spacing,
  bottom: Spacing,
  left: Spacing
): StyledFunction
export function spacing(...args: Spacing[]) {
  return ({ theme }: PropsWithTheme) => {
    // @ts-expect-error: Due to the way that the grafana spacing function is typed,
    // we can't spread the arguments while staying type safe. :/
    return theme.spacing(...args)
  }
}

type FilterKeys<T, Include, Exclude = never> = {
  [P in keyof T]: T[P] extends Exclude
    ? never
    : T[P] extends Include
    ? P
    : never
}[keyof T]

type Colors = Pick<ThemeColors, FilterKeys<ThemeColors, object, Function>>
type Variants<C extends keyof ThemeColors> = Pick<
  ThemeColors[C],
  FilterKeys<ThemeColors[C], string>
>

/**
 * Shorthand for accessing a color from the theme:
 *
 * ```
 * `${color("background", "canvas")}`
 * ```
 * is the same as
 * ```
 * `${({ theme }) => theme.colors.background.canvas}`
 * ```
 */
export const color = <C extends keyof Colors>(
  color: C,
  variant: keyof Variants<C>,
  opacity?: number
) => {
  return ({ theme }: PropsWithTheme) => {
    return opacity === undefined
      ? theme.colors[color][variant]
      : alpha(theme.colors[color][variant] as string, opacity)
  }
}

/**
 * Shorthand for accessing the value of a property.
 *
 * ```
 * `${value("$height")}`
 * ```
 *  is the same as
 * ```
 * `${({ $height }) => $height}`
 * ```
 */
export const value = <P extends PropsWithTheme, T extends keyof P>(
  property: T
) => {
  return (props: P) => {
    return props[property]
  }
}

type TypographyElement = Exclude<
  FilterKeys<ThemeTypography, ThemeTypographyVariant>,
  undefined
>

/**
 * Shorthand the root font-size or that of a specific type of element.
 *
 * ```
 * `${fontSize("h5")}`
 * ```
 * is the same as
 * ```
 * `${({ theme }) => theme.typography.h5.fontSize}`
 * ```
 *
 * To get the base font size, call the function without any arguments:
 * ```
 * `${fontSize()}`
 * ```
 */
export const fontSize = (element?: TypographyElement) => {
  return ({ theme }: PropsWithTheme) => {
    if (element === undefined) {
      return theme.typography.fontSize + 'px'
    }

    return theme.typography[element].fontSize
  }
}

const elementFontWeight = (element: TypographyElement) => {
  return ({ theme }: PropsWithTheme) => {
    return theme.typography[element].fontWeight
  }
}

/**
 * Shorthand for getting the font weight.
 *
 * ```
 * `${fontSize("h5")}`
 * ```
 * is the same as
 * ```
 * `${({ theme }) => theme.typography.h5.fontWeight}`
 * ```
 *
 * To get the root font weights, you can use the helper properties:
 *
 * ```
 * `${fontWeight.bold}`
 * ```
 */
export const fontWeight = Object.assign(elementFontWeight, {
  bold: ({ theme }: PropsWithTheme) => theme.typography.fontWeightBold,
  medium: ({ theme }: PropsWithTheme) => theme.typography.fontWeightMedium,
  regular: ({ theme }: PropsWithTheme) => theme.typography.fontWeightRegular,
  light: ({ theme }: PropsWithTheme) => theme.typography.fontWeightLight,
})

/**
 * Shorthand for getting visualizations colors by name with additional support for alpha/opacity.
 *
 * ```
 * `${colorByName("green")}`
 * ```
 * is the same as
 * ```
 * `${({ theme }) => theme.visualization.getColorByName("green")}`
 * ```
 */
export const colorByName = (name: string, opacity?: number) => {
  return ({ theme }: PropsWithTheme) => {
    return opacity === undefined
      ? theme.visualization.getColorByName(name)
      : alpha(theme.visualization.getColorByName(name), opacity)
  }
}

const maxHeight = (value: ThemeBreakpointsKey) => {
  return ({ theme }: PropsWithTheme) => {
    return theme.breakpoints.down(value)
  }
}

const minHeight = (value: ThemeBreakpointsKey) => {
  return ({ theme }: PropsWithTheme) => {
    return theme.breakpoints.up(value)
  }
}

export const media = {
  maxHeight: {
    sm: maxHeight('sm'),
    md: maxHeight('md'),
    lg: maxHeight('lg'),
    xl: maxHeight('xl'),
    xxl: maxHeight('xxl'),
  },
  minHeight: {
    sm: minHeight('sm'),
    md: minHeight('md'),
    lg: minHeight('lg'),
    xl: minHeight('xl'),
    xxl: minHeight('xxl'),
  },
}
