import { useQuery } from '@tanstack/react-query'

import {
  isInitializationAvailable,
  isInitializationError,
  isInitializationInProgress,
} from 'utils/provisioning'
import { showAlert } from 'utils/showAlert'

import { useDatasource } from '../datasourceContext'
import { toProvisioningStateQueryKey } from './queryKeys'
import { isLockedDown } from './queryClient'
import { timeout } from 'utils/async'

export function useProvisioningState() {
  const { ds } = useDatasource()

  async function getCurrentState() {
    const state = await ds.fetchGrafanaAppInitialized()

    if (isInitializationError(state)) {
      throw new Error(
        state.error ?? 'An error occurred while initializing Grafana k6 cloud.'
      )
    }

    return state
  }

  function startProvisioning() {
    return ds.createAccountProvisioning()
  }

  async function waitForProvisioning() {
    await timeout(1000)
    const state = await getCurrentState()

    return state
  }

  return useQuery({
    queryKey: toProvisioningStateQueryKey(),
    queryFn: async () => {
      let state = await getCurrentState()

      if (state.initialized) {
        return state
      }

      if (isInitializationAvailable(state)) {
        await startProvisioning()
      }

      while (isInitializationInProgress(state)) {
        state = await waitForProvisioning()
      }

      return state
    },
    staleTime: Infinity,
    useErrorBoundary: isLockedDown,
    onError() {
      showAlert('Unable to initialize Grafana k6 cloud.', 'error')
    },
  })
}
